<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import 'flickity/dist/flickity.min.css';
  import type Flickity from 'flickity';
  import { browser } from '$app/env';

  // Flickity has to be imported dynamically to avoid calling window before it exists
  let flickity: Flickity;
  onMount(() => {
    if (!browser) return;
    Promise.all([
      import('flickity'),
      // @ts-ignore
      import('flickity-imagesloaded'),
    ]).then(([{ default: Flickity }]) => {
      flickity?.destroy();
      flickity = new Flickity('.carousel', {
        imagesLoaded: true,
        autoPlay: 4000,
        wrapAround: true,
      });
      flickity.resize();
    });
    // // @ts-ignore - no definition
  });

  onDestroy(() => {
    flickity?.destroy();
  });
</script>

<svelte:window
  on:resize={() => {
    flickity?.resize();
  }}
/>

<div class="carousel">
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/smartboard-and-class.jpg"
      alt="Teacher using uTheory to teach key signatures on a smartboard in a classroom of students."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/grade-view-computer.png"
      alt="A view of the teacher's grade book in uTheory"
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/student-detail-page-computer.png"
      alt="A view of the student progress detailed page in uTheory, showing a student's overall progress as well as detailed progress in different areas of uTheory."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/utheory-student-3.png"
      alt="A student watches a video on uTheory showing how to build a half-diminished seventh chord."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/classes-computer.png"
      alt="The teacher's classes view, where a teacher can create and change class settings on uTheory."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/classes-expanded-computer.png"
      alt="The teacher's class options view, where settings for classes can be changed in uTheory."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/utheory-student-1.png"
      alt="A student practices a rhythm reading exercise on uTheory."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/choose-lessons-computer.png"
      alt="The class lesson assignment page, where teachers can assign lessons and set due dates in uTheory."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/dashboard-computer.png"
      alt="The student's dashboard in uTheory, which shows overall progress and gives links to practice and lessons."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/utheory-student-2.png"
      alt="A student watches a video on writing key signatures in uTheory."
    />
  </div>
  <div class="carousel-cell">
    <img
      src="/img/landing-page/landing-slideshow/400px/new-class-computer.png"
      alt="The create a class dialog menu in uTheory, showing the option to create a class linked to Google Classroom."
    />
  </div>
</div>

<style lang="postcss">
  /* external css: flickity.css */

  .carousel {
    :global(.flickity-prev-next-button) {
      display: none;
    }
    margin-bottom: 45px;

    img {
      display: block;
      max-height: 384px;
      margin: auto;
    }

    .carousel-cell {
      /* max-width: 100%; /* full width */
      height: 384px; /* height of carousel */
      transition: opacity ease-out 1s;
      img {
        transform: scale(0.7);
        transition: transform 1s;
      }

      opacity: 0.2;
    }

    :global(.carousel-cell.is-selected) {
      opacity: 1;
      z-index: 2000;
      :global(img) {
        transform: scale(1);
      }
    }
  }
</style>
