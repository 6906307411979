<script context="module" lang="ts">
  export const load: Load = async ({ fetch }) => {
    const api = new API(getApiPaths(), fetch);
    try {
      const isLoggedIn = await api.$get<boolean>('/auth/is-logged-in', { noAnonymous: true });
      if (isLoggedIn) {
        return {
          status: 302,
          redirect: `/app`,
        };
      }
    } catch (err) {
      console.error(err);
    }
    return {};
  };
</script>

<script lang="ts">
  import Footer from '$lib/root/Footer.svelte';
  import LandingPageHeaderAndNav from '$lib/root/LandingPageHeaderAndNav.svelte';
  import PricingDiv from '$lib/root/PricingDiv.svelte';
  import UTheoryForTeachersDiv from '$lib/root/UTheoryForTeachersDiv.svelte';
  import ShinyAdjectiveCirclesDiv from '$lib/root/ShinyAdjectiveCirclesDiv.svelte';
  import FeaturesDiv from '$lib/root/FeaturesDiv.svelte';
  import PasswordResetModal from '$lib/app/modals/authentication/PasswordResetModal.svelte';
  import LoginModal from '$lib/app/modals/authentication/LoginModal.svelte';
  import RegisterModal from '$lib/app/modals/authentication/RegisterModal.svelte';
  import { goto } from '$app/navigation';
  import { page } from '$app/stores';
  import { closeModal, openModal } from '$lib/stores/modals.store';
  import type { Load } from '@sveltejs/kit';
  import { API } from 'shared/src/utils/api/api';
  import { getApiPaths } from '$lib/stores/getUserStore';
  import { highContrastStore } from '$lib/stores/highContrastTheme.store';
  import PageTitle from '$lib/teach/resources/PageTitle.svelte';
  import type { AuthFlowParameters } from 'shared/definitions/auth-object-definitions';

  function loginRegisterRedirect() {
    goto('/app/dashboard').then(() => {
      if (openSubscriptionModalAfterLogin) {
        openModal('subscribe-modal');
      } else {
        closeModal();
      }
    });
  }

  const initialModal = $page.url.searchParams.get('modal');
  let isTeacher = !!$page.url.searchParams.get('isTeacher');
  if (initialModal) {
    openModal(initialModal);
  }

  let openSubscriptionModalAfterLogin = false;
  let authFlowParameters: AuthFlowParameters;

  $: authFlowParameters = {
    loginRedirect: openSubscriptionModalAfterLogin
      ? '/app/dashboard?modal=subscribe-modal'
      : undefined,
  };
</script>

<PageTitle
  title="uTheory: Online music theory, rhythm and ear training"
  hideTitle
  doNotAutoCapitalize
  description={`
    Learn and teach the fundamentals of music theory, rhythm and ear training with the
    most advanced and interactive online platform. Through video tutorials, interactive
    lessons, mastery tests and more, uTheory has helped over 100,000 students master
    music theory fundamentals including intervals, key signatures, chords, and more.
  `}
/>
<div class:high-contrast={$highContrastStore}>
  <PasswordResetModal />
  <LoginModal
    doNotCloseModal
    on:loginComplete={loginRegisterRedirect}
    parameters={authFlowParameters}
    on:close={() => {
      openSubscriptionModalAfterLogin = false;
    }}
  />
  <RegisterModal
    doNotCloseModal
    accountType={isTeacher ? 'teacher' : 'unknown'}
    on:registrationComplete={loginRegisterRedirect}
    parameters={authFlowParameters}
    on:close={() => {
      openSubscriptionModalAfterLogin = false;
      isTeacher = !!$page.url.searchParams.get('isTeacher');
    }}
  />
  <LandingPageHeaderAndNav />
  <FeaturesDiv />
  <UTheoryForTeachersDiv />
  <PricingDiv
    on:planSelected={() => {
      openSubscriptionModalAfterLogin = true;
      isTeacher = false;
      openModal('register');
    }}
  />
  <ShinyAdjectiveCirclesDiv />
  <Footer />
</div>
