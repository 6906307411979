<div class="landing-testimonial-card bg-blue-50 my-2 card card-margins card-padding">
  <figure>
    <q class="quote [quotes:'\201C'_'\201D']">
      <p class="text-gray-500 text-xl"><slot /></p>
    </q>
    <cite>
      <p class="text-gray-300 high-contrast:text-gray text-right"><slot name="author" /></p>
    </cite>
  </figure>
</div>

<style lang="postcss">
  .landing-testimonial-card {
    :global(p) {
      @apply indent-8 my-4 text-2lg;
    }
    .quote {
      @apply before:content-[open-quote] before:text-cyan before:text-[3em] before:h-0;
      @apply before:w-0 before:[float:left] pt-[0.5em] translate-x-[-0.15em] translate-y-[0.1em] after:content-[''];
    }
  }
</style>
