<script lang="ts">
  import Button from '$lib/components/Button.svelte';
  import GroupLicensePricingCalculator from '$lib/teach/GroupLicensePricingCalculator.svelte';
  import { fade } from 'svelte/transition';
  import { redirectForLicensePurchase, redirectForLicenseQuote } from './LicenseQuotingUtils';
  let groupLicensePricingCalculator: GroupLicensePricingCalculator;
</script>

<div class="xs:p-2 md:p-4">
  <div in:fade class="grid xs:space-x-2 md:space-x-4 text-white grid-cols-1 md:grid-cols-2">
    <div class="p-2 xs:p-0">
      <p class="mb-4 text-xl md:text-2xl">uTheory is free for teachers.</p>
      <p class="mb-4 text-xl md:text-2xl">
        A group license for education gives students in your classes unlimited access to uTheory.
        The costs for an institution start at 75% off the individual subscription rate. Group
        licenses include custom testing features.
      </p>
      <p class="mb-4 text-xl md:text-2xl">
        Licenses last one year, and can begin on any day of your choosing.
      </p>
      <p class="mb-4 text-xl md:text-2xl">
        To learn more about using group licenses, check out these <a
          class="underline"
          href="https://info.utheory.com/introducing-group-licenses-73e5915e4ba4/"
          >frequently asked questions</a
        >
        or download our
        <a
          class="underline"
          target="_blank"
          rel="noreferrer"
          href="pdf/utheory-purchasing-guide.pdf">license purchasing guide</a
        >.
      </p>
    </div>
    <div class="xs:p-2 xs:m-2 text-lg border border-white">
      <GroupLicensePricingCalculator
        tight
        highlightClass="text-white"
        bind:this={groupLicensePricingCalculator}
      />
      <div class="p-2 mt-2 flex justify-between">
        <Button
          on:click={() => {
            const details = groupLicensePricingCalculator.getDetails();
            redirectForLicenseQuote(details);
          }}
          class="shadow-none">Get Quote</Button
        >
        <Button
          on:click={() => {
            const details = groupLicensePricingCalculator.getDetails();
            redirectForLicensePurchase(details);
          }}
          class="shadow-none">Purchase</Button
        >
      </div>
    </div>
  </div>
</div>
