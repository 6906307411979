<script lang="ts">
  import Button from '$lib/components/Button.svelte';
  import { onMount } from 'svelte';
  let showVideoIFrame = false;
  let iFrameWidth: string;
  let iFrameHeight: string;

  onMount(() => {
    resizeIFrame();
  });

  function resizeIFrame(): void {
    const width = document.body.clientWidth;
    // console.log('resized!', width);
    let videoWidth;
    let videoHeight;
    if (width > 1200) {
      videoWidth = Math.min(width - 600, 690);
      videoHeight = (videoWidth * 9) / 16;
    } else {
      videoWidth = width * 0.5;
      videoHeight = (videoWidth * 9) / 16;
    }
    [iFrameWidth, iFrameHeight] = [`${videoWidth}px`, `${videoHeight}px`];
  }
</script>

<svelte:window on:resize={resizeIFrame} />
<div
  class="wrapper bg-gradient-to-br from-green-500 to-green-700 high-contrast:from-green-700 high-contrast:to-green-900"
>
  <h2 class="text-white text-4xl text-center pt-8">uTheory for Learners</h2>
  <div class="margins flex w-full flex-col md:flex-row justify-between items-center">
    <div class="text-center pt-4 py-8">
      <ul class="flex flex-col text-left justify-around space-y-8">
        <li class="listitem">300+ Video tutorials</li>
        <li class="listitem">100+ Interactive exercises</li>
        <li class="listitem">
          <a
            href="https://info.utheory.com/rhythm-reading-awaits-54966f3b273f/"
            class="hover:underline">Real-time rhythm reading</a
          >
        </li>
        <li class="listitem">Effective ear training</li>
        <li class="listitem">Works on computers, tablets, and phones</li>
      </ul>
      <Button
        as="a"
        href="/app/lessons"
        class="py-2 px-12 text-2xl rounded-full mt-8 shadow-none hover:bg-cyan-500 ease-linear"
        color="orange">Start Learning</Button
      >
    </div>
    <div class="pt-4 py-8">
      {#if !showVideoIFrame}
        <button on:click={() => (showVideoIFrame = true)} class="block max-w-[550px]">
          <img
            src="/img/landing-page/main-bg-5.png"
            alt="uTheory runs on a tablet, phone, computer or Chromebook."
          />
        </button>
      {:else}
        <div class="px-4">
          <iframe
            title="YouTube video on uTheory for Educators"
            width={iFrameWidth}
            height={iFrameHeight}
            src="https://www.youtube.com/embed/Fr-bIIBlyb8?autoplay=0&rel=0"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="postcss">
  .listitem {
    @apply text-white pl-12 text-2xl bg-[url('/img/landing-page/yep.png')] bg-no-repeat bg-[center_left];
  }
</style>
