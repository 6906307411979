<script lang="ts">
  import Button from '$lib/components/Button.svelte';

  import TestingLicensePricing from '$lib/teach/TestingLicensePricing.svelte';
  import { fade } from 'svelte/transition';
  import { redirectForLicensePurchase, redirectForLicenseQuote } from './LicenseQuotingUtils';
  let testingLicensePricingCalculator: TestingLicensePricing;
</script>

<div in:fade class="grid text-white grid-cols-1 md:grid-cols-2 xs:m-2">
  <div class="p-2">
    <p class="mb-4 text-xl md:text-2xl">
      A testing-only license lets you create custom tests for placement purposes, or for use as
      exams with a class you teach on uTheory.
    </p>
    <p class="mb-4 text-xl md:text-2xl">
      Licenses last one year, and can begin on any day of your choosing.
    </p>
    <p class="mb-4 text-xl md:text-2xl">
      To learn more about using a testing-only licenses, check out these <a
        class="underline"
        target="_blank"
        rel="noreferrer"
        href="https://info.utheory.com/customized-music-theory-placement-tests-61c97f978eed/"
        >frequently asked questions</a
      >.
    </p>
  </div>
  <div class="p-2 text-lg border border-white">
    <TestingLicensePricing
      class="text-white"
      highlightClass="text-white font-medium"
      bind:this={testingLicensePricingCalculator}
    />
    <div class="p-2 mt-2 flex justify-between">
      <Button
        on:click={() => {
          const details = testingLicensePricingCalculator.getDetails();
          redirectForLicenseQuote(details);
        }}
        class="shadow-none">Get Quote</Button
      >
      <Button
        on:click={() => {
          const details = testingLicensePricingCalculator.getDetails();
          redirectForLicensePurchase(details);
        }}
        class="shadow-none">Purchase</Button
      >
    </div>
  </div>
</div>
