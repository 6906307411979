<script lang="ts">
  import LandingPageTestimonialCard from './LandingPageTestimonialCard.svelte';
</script>

<ul class="px-2 grid grid-cols-1 items-stretch md:grid-cols-2 lg:grid-cols-4 mt-8">
  <LandingPageTestimonialCard>
    <p>
      Thanks a million for uTheory! Music theory has never seemed so straight-forward before. It's
      like a whole new world!
    </p>
    <div slot="author">
      Clara Dahmer<br />
      Oberlin Conservatory Student
    </div>
  </LandingPageTestimonialCard>
  <LandingPageTestimonialCard>
    <p>
      I can't imagine teaching a music theory fundamentals class without uTheory. The progression of
      topics, clear explanations, and the opportunity for students to practice at their own pace or
      mine make uTheory the perfect complement to my lectures, live workshops and traditional
      pencil-and-paper assignments.
    </p>
    <div slot="author">
      Joseph Lubben<br />
      Associate Professor of Music Theory<br />
      Oberlin Conservatory
    </div>
  </LandingPageTestimonialCard>
  <LandingPageTestimonialCard>
    <p>
      My first-year students arrive with vastly different experience, and it can be difficult to
      meet their diverse needs in the classroom.
    </p>
    <p>
      uTheory has really leveled the playing field and set them up for success throughout the
      curriculum.
    </p>
    <div slot="author">
      Megan Long<br />
      Associate Professor of Music Theory<br />
      Oberlin Conservatory
    </div>
  </LandingPageTestimonialCard>
  <LandingPageTestimonialCard>
    <p>
      uTheory has streamlined our application process by integrating online music theory testing
      with our student information system. But more importantly, our applicants experience a well
      designed tool for learning that goes far beyond a static theory exam.
    </p>
    <div slot="author">
      Josh Teaster<br />
      Associate Director<br />
      Oberlin Conservatory Admissions
    </div>
  </LandingPageTestimonialCard>
</ul>
