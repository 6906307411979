<script>
  import { toggleHighContrastStore } from '$lib/stores/highContrastTheme.store';
  const year = new Date().getFullYear();
</script>

<footer class="bg-gray-800 wrapper">
  <div class="margins">
    <ul class="flex flex-wrap w-full justify-around align-top text-xs text-white text-center">
      <li class="m-5">
        <a class="hover:underline text-sm" href="/privacy">Privacy Policy & <br /> Terms of Use</a>
      </li>
      <li class="m-5">
        <button class="hover:underline text-sm" on:click={toggleHighContrastStore}
          >Use or disable <br /> high-contrast theme</button
        >
      </li>
      <li class="m-5">
        <a
          class="hover:underline text-sm"
          href="https://info.utheory.com/accessibility-roadmap-b5e1926d7e3a/">Accessibility</a
        >
      </li>
      <li class="m-5">
        <a class="hover:underline text-sm" href="mailto:hello@utheory.com">hello@utheory.com</a><br
        />
        <a class="hover:underline text-sm" href="tel:+1866-838-0556">(866) 838-0556</a>
      </li>
      <li class="m-5">
        &copy; {year} <br />
        uTheory.com, LLP
      </li>
    </ul>
  </div>
</footer>
