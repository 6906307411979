<script>
  import Button from '$lib/components/Button.svelte';
  import LandingPageTestimonials from './LandingPageTestimonials.svelte';
  import LandingTeacherFeatureCheckItem from './LandingTeacherFeatureCheckItem.svelte';
  import LandingTeacherFeaturesCarousel from './LandingTeacherFeaturesCarousel.svelte';
  const featureListBullets = [
    'Create and configure classes',
    'Track student progress',
    'Assign lessons and practice',
    'View detailed assessment data',
    'Create custom tests and quizzes',
    'Link to your Google Classroom',
  ];
</script>

<div class="w-full py-8 bg-white lg:px-10 md:px-6 px-4">
  <h2 class="text-cyan text-4xl text-center pb-4">uTheory for Teachers</h2>
  <div
    class="flex flex-col lg:flex-row justify-around lg:justify-between items-center lg:items-start my-2"
  >
    <div class="w-full lg:w-auto lg:flex-grow h-[425px] pb-20 mb-20 overflow-hidden">
      <LandingTeacherFeaturesCarousel />
    </div>

    <div class="md:pl-8 lg:pl-16 text-center">
      <ul class="features-list flex flex-col text-left justify-around space-y-6">
        {#each featureListBullets as featureText}
          <LandingTeacherFeatureCheckItem>{featureText}</LandingTeacherFeatureCheckItem>
        {/each}
      </ul>
      <Button
        as="a"
        href="/teach"
        class="py-2 px-12 text-2xl rounded-full mt-8 hover:bg-cyan-500 ease-linear"
        color="orange">Teach with uTheory</Button
      >
    </div>
  </div>
  <div class="margins my-8">
    <p class="paragraph-text">Over 100,000 students have learned with uTheory.</p>
    <p class="paragraph-text">
      uTheory is trusted by school districts and colleges around the world, including the Miami-Dade
      County Public Schools, Baylor University and the Oberlin Conservatory.
    </p>
    <p class="paragraph-text">And students actually enjoy using it.</p>
    <LandingPageTestimonials />
  </div>
</div>

<style lang="postcss">
  .paragraph-text {
    @apply text-gray text-2xl text-center px-8 py-2;
  }
</style>
