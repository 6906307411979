<script lang="ts">
  import { goto } from '$app/navigation';
  import { createEventDispatcher } from 'svelte';

  import { fade } from 'svelte/transition';
  import IndividualPricingTabColumn from './IndividualPricingTabColumn.svelte';

  const dispatch = createEventDispatcher<{ planSelected: null; showEducationPricing: null }>();

  const pricingOptions = [
    {
      title: 'Starter Plan',
      price: 'Free',
      hookLine: 'Great for casual learners',
      featureList: [
        'Ten video lessons or exercises daily',
        ' Detailed progress tracking     ',
        'Desktop & mobile friendly',
        'Synchronizes across devices',
      ],
      circleBackgroundClass: 'bg-red high-contrast:bg-red-800',
      buttonText: 'Start Now',
      onClick() {
        goto('/app');
      },
    },
    {
      title: 'Monthly Unlimited',
      price: '$5.99/mo',
      hookLine: 'Most popular option',
      featureList: [
        'All video lessons',
        'All exercises',
        'All practice sessions',
        'All checkpoints',
        'Detailed progress tracking',
        'Desktop & mobile friendly',
        'Synchronizes across devices',
      ],
      circleBackgroundClass: 'bg-green',
      buttonText: 'Choose plan',
      onClick() {
        dispatch('planSelected');
      },
    },
    {
      title: 'Yearly Unlimited',
      price: '$39.99/yr',
      hookLine: 'Best value — $3.33/mo billed yearly',
      featureList: [
        'All video lessons',
        'All exercises',
        'All practice sessions',
        'All checkpoints',
        'Detailed progress tracking',
        'Desktop & mobile friendly',
        'Synchronizes across devices',
      ],
      circleBackgroundClass: 'bg-orange',
      buttonText: 'Choose plan',
      onClick() {
        dispatch('planSelected');
      },
    },
    {
      title: 'Education Licenses',
      price:
        '<div class="-mt-3 leading-tight"><span class="text-xs">From</span><br>$10 to $3 <br><span class="block text-xs leading-4 pt-1">per student<br>by quantity</span></div>',
      hookLine: 'Unlock uTheory unlimited for your students',
      featureList: [
        'Complete LMS for Music Theory',
        'Assign content',
        'View grades',
        'Track student learning',
        'Create custom tests',
        'Team teach classes',
        'Never grade a theory assignment again',
      ],
      circleBackgroundClass: 'bg-blue-300 high-contrast:bg-purple-700',
      buttonText: 'Learn More',
      onClick() {
        dispatch('showEducationPricing');
      },
    },
  ];
</script>

<div
  in:fade
  class="mx-4 grid grid-cols-1 last:border-r-2 last:border-white md:grid-cols-4 text-center gap-x-0 gap-y-6"
>
  {#each pricingOptions as { onClick, title, price, hookLine, featureList, circleBackgroundClass, buttonText }}
    <IndividualPricingTabColumn {circleBackgroundClass} on:click={onClick}>
      <svelte:fragment slot="title">{title}</svelte:fragment>
      <svelte:fragment slot="price">{@html price}</svelte:fragment>
      <svelte:fragment slot="hookline">{hookLine}</svelte:fragment>
      <svelte:fragment slot="features">
        {#each featureList as featureText}
          <li>{featureText}</li>
        {/each}
      </svelte:fragment>
      <svelte:fragment slot="buttonText">{buttonText}</svelte:fragment>
    </IndividualPricingTabColumn>
    <!-- 
    <div
      class="relative pb-24 group text-white border-l-2 border-y-2 border-white hover:text-gray-800 hover:bg-green-200 transition-colors duration-200 ease-linear"
    >
      <H level={2} class="text-3xl px-4 my-2 md:h-20 group-hover:text-gray-800 text-white"
        >{title}</H
      >
      <div
        class="flex text-xl p-2 {circleBackgroundClass} my-3 flex-row items-center justify-center rounded-full h-28 w-28 group-hover:bg-green-700 m-auto text-white"
      >
        <span>{price}</span>
      </div>
      <strong class="inline-block mx-3 my-4">{hookLine}</strong><br />
      <ul class="py-1 px-4">
        {#each featureList as featureText}
          <li class="mb-2">{featureText}</li>
        {/each}
      </ul>
      <Button
        class="shadow-none absolute block my-4 mx-auto left-1/2 -translate-x-1/2 bottom-2 whitespace-nowrap px-6 ease-linear duration-200 ease-in transition-all hover:bg-cyan-500 high-contrast:hover:bg-blue text-2xl rounded-3xl"
        color="orange">{buttonText}</Button
      >
    </div> -->
  {/each}
</div>
