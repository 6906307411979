<script lang="ts">
  import Button from '$lib/components/Button.svelte';

  const adjectiveDivContents = [
    {
      adjective: 'Engaging',
      description: 'Learn by doing with dozens of interactive exercises',
      filename: 'adjective-icon-1.png',
      alt: 'brain waves',
    },
    {
      adjective: 'Adaptive',
      description: "Practice what you need, not what you don't",
      filename: 'adjective-icon-2.png',
      alt: 'several people',
    },
    {
      adjective: 'Motivating',
      description: 'Track your progress over time in multiple areas',
      filename: 'adjective-icon-3.png',
      alt: 'graph with rising line to the right',
    },
    {
      adjective: 'Accurate',
      description: 'Designed by top college music faculty',
      filename: 'adjective-icon-4.png',
      alt: 'graduation cap',
    },
  ];
</script>

<div class="bg-white text-center wrapper">
  <div class="margins">
    <div class="mt-8 md:mt-16 grid grid-cols-2 md:grid-cols-4 justify-items-center">
      {#each adjectiveDivContents as { adjective, description, filename, alt }}
        <div class="flex flex-col items-center justify-start mb-8 md:mb-16">
          <img class="max-w-[122px]" src={`/img/landing-page/${filename}`} {alt} />
          <span class="text-2xl mt-5 text-blue-400">{adjective}</span>
          <p class="text-center mt-3 px-3 text-gray max-w-xs">{description}</p>
        </div>
      {/each}
    </div>
    <Button
      as="a"
      href="/app"
      class="mb-8 md:mb-16 px-12 py-2 text-2xl rounded-3xl hover:bg-green ease-linear transition-colors"
      color="orange">Try uTheory Now</Button
    >
  </div>
</div>
