<script>
  import Button from '$lib/components/Button.svelte';
  import H from '$lib/components/H.svelte';
  import Logo from '$lib/components/Logo.svelte';
  import { openModal } from '$lib/stores/modals.store';
  import LandingVr from './LandingVr.svelte';
  let videoLoaded = false;
</script>

<div class="relative min-h-[800px] wrapper">
  <header>
    <div class="margins xs:flex">
      <div class="card-padding">
        <Logo class="h-12" color="white" />
      </div>
      <nav class="flex-grow flex justify-end mt-4">
        <ul class="flex flex-wrap justify-end">
          <li class="navitem"><a href="/app/lessons">Lessons</a></li>
          <li class="navitem"><LandingVr /><a href="/app/skills">Practice</a></li>
          <li class="navitem"><LandingVr /><a href="/teach">Teach</a></li>
          <li class="navitem"><LandingVr /><a href="#pricing">Pricing</a></li>
          <li class="navitem"><LandingVr /><a href="https://info.utheory.com">Blog</a></li>
          <li class="navitem">
            <LandingVr />
            <button
              class="text-right"
              on:click={() => {
                openModal('register');
              }}>Register</button
            >
          </li>
          <li class="navitem flex justify-end">
            <LandingVr />
            <button
              class="text-right"
              on:click={() => {
                openModal('login');
              }}>Login</button
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <div class="mx-auto mt-16 xs:mt-24 md:mt-32 pb-8 text-center max-w-4/5 space-y-14">
    <H level={2} class="text-cyan-500 text-5xl shadow-white drop-shadow-lg"
      >Music Theory Learning Made Easy</H
    >
    <img
      class="m-auto"
      src="img/landing-page/slashes.png"
      alt="zig-zag horizontal divider"
      aria-hidden="true"
    />
    <H level={3} class="text-white text-lg leading-10 font-light">
      Learn clefs, scales, keys, intervals, chords, ear training & rhythm through video tutorials &
      interactive exercises in your web browser.
    </H>
    <Button
      as="a"
      href="/app"
      class="py-2 px-12 text-2xl hover:bg-cyan-500 rounded-full shadow-none"
      color="orange">Get Started</Button
    >
  </div>

  <!-- Background poster class -- only visible until video starts playing. -->
  <div
    class="{videoLoaded
      ? 'bg-none'
      : 'bg-[url(/img/landing-page/header-bg.jpg)] bg-cover bg-center'} absolute top-1/2 left-1/2 min-w-full min-h-full
    -translate-x-1/2 -translate-y-1/2 -z-50 bg-black"
  />
  <video
    class="
    {videoLoaded ? 'opacity-40 high-contrast:opacity-10' : 'opacity-0'}
  absolute top-1/2 left-1/2 min-w-full min-h-full w-auto h-auto
  -translate-x-1/2 -translate-y-1/2 -z-40 box-border object-cover
  "
    on:play={() => {
      videoLoaded = true;
    }}
    on:playing={() => {
      videoLoaded = true;
    }}
    on:timeupdate={() => {
      videoLoaded = true;
    }}
    webkit-playsinline
    playsinline
    muted
    autoplay
    loop
    poster="/img/landing-page/header-bg.jpg"
    id="bg-video"
    x-webkit-airplay="deny"
    aria-label="Looping video of a person playing piano, viewed from above the piano"
  >
    <source src="https://cdn.utheory.com/videos/utheory.mp4" type="video/mp4" />
  </video>
</div>

<style lang="postcss">
  .navitem {
    @apply text-white my-1 h-fit;
    a,
    button {
      @apply hover:text-orange transition-colors ease-linear;
    }
  }
</style>
