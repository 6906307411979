<script>
  import Button from '$lib/components/Button.svelte';

  import H from '$lib/components/H.svelte';

  export let circleBackgroundClass = '';
</script>

<div
  class="relative pb-24 group text-white border-l-2 border-y-2 border-white hover:text-gray-800 hover:bg-green-200 transition-colors duration-200 ease-linear"
>
  <H level={2} class="text-3xl px-4 my-2 md:h-20 group-hover:text-gray-800 text-white"
    ><slot name="title" /></H
  >
  <div
    class="flex text-xl p-2 {circleBackgroundClass} my-3 flex-row items-center justify-center rounded-full h-28 w-28 group-hover:bg-green-700 m-auto text-white"
  >
    <slot name="price" />
  </div>
  <strong class="inline-block mx-3 my-4"><slot name="hookline" /></strong><br />
  <div class="plan-feature-list">
    <ul class="py-1 px-4">
      <slot name="features" />
    </ul>
  </div>
  <Button
    on:click
    class="shadow-none absolute block my-4 mx-auto left-1/2 -translate-x-1/2 bottom-2 whitespace-nowrap px-6  duration-200 ease-in transition-all hover:bg-blue high-contrast:hover:bg-blue text-2xl rounded-full"
    color="orange"><slot name="buttonText" /></Button
  >
</div>

<style lang="postcss">
  .plan-feature-list {
    ul {
      @apply py-1 px-4;
      :global(li) {
        @apply mb-2;
      }
    }
  }
</style>
