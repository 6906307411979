<script lang="ts">
  import IndividualPricingTab from './IndividualPricingTab.svelte';
  import GroupLicensesTab from './GroupLicensesTab.svelte';
  import TestingOnlyLicensesTab from './TestingOnlyLicensesTab.svelte';
  import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@rgossiaux/svelte-headlessui';
  const tabs: { id: 'individual' | 'groupLicenses' | 'testingOnly'; text: string }[] = [
    { id: 'individual', text: 'Individual Pricing' },
    { id: 'groupLicenses', text: 'Group Licenses' },
    { id: 'testingOnly', text: 'Testing-Only Licenses' },
  ];

  function tabClassFunction({ selected }: { selected?: boolean }) {
    if (selected) return 'text-white underline px-4';
    return 'text-gray-100 high-contrast:text-white px-4';
  }
</script>

<div id="pricing" class="py-8 bg-blue-400 high-contrast:bg-blue text-lg">
  <div class="margins">
    <TabGroup>
      <div class="landing-page-pricing-tabs">
        <TabList>
          <div class="flex justify-center mb-4 divide-x-2">
            {#each tabs as { id, text }}
              <Tab class={tabClassFunction}>{text}</Tab>
            {/each}
          </div>
        </TabList>
      </div>
      <TabPanels>
        <TabPanel>
          <IndividualPricingTab
            on:planSelected
            on:showEducationPricing={() => {
              const buttons = document.querySelectorAll('.landing-page-pricing-tabs button');
              const tabButton = buttons[1];
              if (tabButton) {
                // @ts-ignore
                tabButton.click();
              }
            }}
          />
        </TabPanel>
        <TabPanel>
          <GroupLicensesTab />
        </TabPanel>
        <TabPanel>
          <TestingOnlyLicensesTab />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</div>
